@use 'sass:map';
@use 'sass:meta';
@use '../../base' as *;
@use '../../themes/schemas' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// Dialog Theme
/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Map} $elevations [null] - The elevations (shadows) map to be used.
/// @param {Color} $background [null] - The dialog background color.
/// @param {Color} $title-color [null] - The dialog title text color.
/// @param {Color} $message-color [null] - The dialog message text color.
/// @param {box-shadow} $shadow [null] - The shadow used for the dialog.
/// @param {List} $border-radius [null] - The border radius used for dialog component.
/// @param {Color} $border-color [null] - The border color used for dialog component.
/// @requires $light-material-schema
/// @example scss Change the background color
///   $my-dialog-theme: dialog-theme($background: black);
///   // Pass the theme to the css-vars() mixin
///   @include css-vars($my-dialog-theme);
@function dialog-theme(
    $schema: $light-material-schema,
    $elevations: null,

    $border-radius: null,
    $border-color: null,

    $background: null,
    $title-color: null,
    $message-color: null,
    $shadow: null,
) {
    $name: 'igx-dialog';
    $selector: '.igx-dialog';

    $dialog-schema: ();

    @if map.has-key($schema, 'dialog') {
        $dialog-schema: map.get($schema, 'dialog');
    } @else {
        $dialog-schema: $schema;
    }

    $theme: digest-schema($dialog-schema);
    $meta: map.get($theme, '_meta');

    @if not($title-color) and $background{
        $title-color: text-contrast($background);
    }

    @if not($message-color) and $background{
        @if meta.type-of($background) == 'color' {
            $message-color: rgba(text-contrast($background), .8);
        }
    }

    @if not($shadow) {
        $elevation: map.get($dialog-schema, 'elevation');
        $shadow: elevation($elevation);
    }

    @return extend($theme, (
        name: $name,
        selector: $selector,
        border-radius: $border-radius,
        background: $background,
        title-color: $title-color,
        message-color: $message-color,
        shadow: $shadow,
        border-color: $border-color,
        theme: map.get($schema, '_meta', 'theme'),
        _meta: map.merge(if($meta, $meta, ()), (
            variant: map.get($schema, '_meta', 'theme')
        )),
    ));
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
@mixin dialog($theme) {
    @include css-vars($theme);

    $variant: map.get($theme, '_meta', 'variant');
    $bootstrap-theme: map.get($theme, '_meta', 'variant') == 'bootstrap';

    $dialog-min-width: map.get((
        'material': rem(280px),
        'fluent': rem(288px),
        'bootstrap': rem(288px),
    ), $variant);

    $dialog-title-padding: map.get((
        'material': rem(16px) rem(24px) rem(0px) rem(24px),
        'fluent': rem(16px) rem(24px) rem(24px) rem(24px),
        'bootstrap': rem(16px),
        'indigo': rem(24px) rem(24px) 0 rem(24px),
    ), $variant);

    $dialog-message-padding: map.get((
        'material': rem(14px) rem(24px),
        'fluent': 0 rem(24px) rem(20px) rem(24px),
        'bootstrap': rem(16px),
        'indigo': rem(16px) rem(24px),
    ), $variant);

    $dialog-actions-padding: map.get((
        'material': 0 rem(8px) rem(8px),
        'fluent': 0 rem(24px) rem(24px) rem(24px),
        'bootstrap': rem(16px),
        'indigo': rem(16px) rem(24px) rem(24px) rem(24px),
    ), $variant);

    %igx-dialog-display {
        outline-style: none;
    }

    %igx-dialog--hidden {
        display: none;
    }

    %igx-dialog-window {
        position: relative;
        min-width: $dialog-min-width;
        border: rem(1px) solid var-get($theme, 'border-color');
        border-radius: var-get($theme, 'border-radius');
        background: var-get($theme, 'background');
        box-shadow: var-get($theme, 'shadow');
        overflow: hidden;

        .igx-calendar {
            min-width: rem(320px);
        }

        .igx-calendar--vertical {
            min-width: rem(496px);
        }
    }

    %igx-dialog-title {
        display: flex;
        color: var-get($theme, 'title-color');
        padding: $dialog-title-padding;

        @if $bootstrap-theme {
            border-bottom: rem(1px) solid var-get($theme, 'border-color');
        }
    }

    %igx-dialog-content {
        color: var-get($theme, 'message-color');
        padding: $dialog-message-padding;
        // The 2 rules below are related to https://github.com/IgniteUI/igniteui-angular/issues/11300
        position: relative;
        z-index: 0;
    }

    %igx-dialog-message {
        display: inline-block;
        max-width: 40ch;

        @media all and (-ms-high-contrast: none)
        {
            max-width: map.get((
                'material': 62ch,
                'fluent': 48ch,
                'bootstrap': 60ch,
                'indigo': 48ch,
            ), $variant);
        }
    }

    %igx-dialog-actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        padding: $dialog-actions-padding;
        gap: if($variant == 'indigo', rem(16px), rem(8px));

        @if $bootstrap-theme {
            border-top: rem(1px) solid var-get($theme, 'border-color');
        }

        @if $variant == 'indigo' {
            .igx-button {
                --ig-size: 2;
            }
        }
    }
}

/// Adds typography styles for the igx-dialog component.
/// Uses the 'h6' and 'body-1' category from the typographic scale.
/// @group typography
/// @param {Map} $categories [(title: 'h6', content: 'body-1')] - The categories from the typographic scale used for type styles.
@mixin dialog-typography($categories: (
    title: 'h6',
    content: 'body-1')
) {
    $title: map.get($categories, 'title');
    $content: map.get($categories, 'content');

    %igx-dialog-title {
        @include type-style($title) {
            margin: 0;
        }
    }

    %igx-dialog-content {
        @include type-style($content) {
            margin: 0;
        }

        > * {
            letter-spacing: normal;
        }
    }
}
