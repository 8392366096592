
@use "@infragistics/igniteui-angular/theming" as *;
// Uncomment the following lines if you want to add a custom palette:
// $primary: #731963 !default;
// $secondary: #ce5712 !default;
// $app-palette: palette($primary, $secondary, $surface);

/* autoprefixer grid: on */

@include core();
@include typography($font-family: $material-typeface, $type-scale: $material-type-scale);
@include theme($default-palette);

/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

.sentenceSell {
    color: red;
}

.sentenceBuy {
    color: #5d9eff;
}

.contextMenuLink:hover {
    background-color: #5d9eff;
}


.hoverable-warning {
    &:hover {
        background-color: orange;
    }
}

.hoverable-help {
    &:hover {
        background-color: mediumpurple;
    }
}

.hoverable-danger {
    &:hover {
        background-color: red;
    }
}

.main-table-container {
    max-height: 84vh;
    overflow-y: auto;
}

.igx-grid.dark-mode {
    --igx-grid-header-background: #434b54;
    --igx-grid-header-border-width: 1px;
    --igx-grid-header-border-style: solid;
    --igx-grid-header-border-color: #5c646c;
    --igx-grid-header-selected-background: #5c646c;
    --igx-grid-header-text-color: white;

    --igx-grid-row-odd-background: #3C434B;
    --igx-grid-row-odd-text-color: white;
    --igx-grid-row-even-background: #434b54;
    --igx-grid-row-even-text-color: white;
    --igx-grid-row-border-color: #5c646c;
    --igx-grid-row-hover-background: #lightgreen;
    --igx-grid-row-hover-text-color: white;
    --igx-grid-row-selected-background: #5c646c;
    --igx-grid-row-selected-text-color: white;

    --igx-input-group-filled-text-color: black;
    --igx-input-group-focused-text-color: black;
    --igx-input-group-placeholder-color: black;
    --igx-input-group-border-color: #5c646c;
    --igx-input-group-fillex-text-hover-color: black;

    --igx-grid-grouparea-background: #434b54;
    --igx-grid-group-row-background: #434b54;
    --igx-grid-group-label-text: white;
    --igx-grid-group-label-icon-color: white;

    --igx-paginator-background-color: #434b54;
    --igx-paginator-text-color: white;
    --igx-paginator-border-color: #5c646c;

    --igx-grid-content-background: #434b54;
    --igx-grid-border-color: #5c646c;

    --igx-grid-cell-selected-background: #434b54;
    --igx-grid-cell-selected-text-color: white;
    --igx-grid-cell-active-border-color: #5d9eff;
    --igx-grid-cell-hover-background: #5d9eff;

    --igx-grid-filtering-header-background: #434b54;
    --igx-grid-filtering-row-background: #434b54;
    --igx-grid-filtering-header-text-color: white;
    --igx-grid-filtering-background-and: #434b54;


    --igx-input-prefix-color: white;
    --igx-input-prefix-background: white;

    --igx-grid-toolbar-background-color: #434b54;
    --igx-grid-toolbar-border-color: #5c646c;
    --igx-grid-toolbar-title-text-color: white;
    --igx-grid-toolbar-higing-button-color: white;

    --igx-button-foreground: white !important;
    --igx-button-background-color: #434b54 !important;
    --igx-button-hover-background-color: #434b54 !important;
    --igx-button-border-color: white !important;
    --igx-button-hover-border-color: #5d9eff !important;
    --igx-button-hover-foreground: #5d9eff !important;
    --igx-button-icon-color-hover: #5d9eff !important;
    --igx-button-icon-color: white !important;

    --igx-grid-excel-filtering-subheader-foreground: white;
    --igx-grid-excel-filtering-actions-foreground: white;
}