@use 'sass:map';
@use '../../base' as *;
@use '../../themes/schemas' as *;

////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// @param {Map} $schema [$light-material-schema] - The schema used as basis for styling the component.
/// @param {Color} $color [null] - The color of the ripple.
/// @requires $light-material-schema
/// @example scss Change the color
///   $my-ripple-theme: igx-ripple-theme($color: yellow);
///   // Pass the theme to the css-vars() mixin
///   @include css-vars($my-ripple-theme);
@function ripple-theme(
    $schema: $light-material-schema,
    $color: null
) {
    $name: 'igx-ripple';
    $selector: '[igxRipple]';

    $ripple-schema: ();

    @if map.has-key($schema, 'ripple') {
        $ripple-schema: map.get($schema, 'ripple');
    } @else {
        $ripple-schema: $schema;
    }

    $theme: digest-schema($ripple-schema);

    @return extend($theme, (
        name: $name,
        selector: $selector,
        color: $color,
        theme: map.get($schema, '_meta', 'theme'),
    ));
}

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The theme used to style the component.
@mixin ripple($theme) {
    @include css-vars($theme);

    %igx-ripple-display {
        display: block;
        position: absolute;
        border-radius: border-radius(50%);
        background: var-get($theme, 'color');
        pointer-events: none;
        transform-origin: center;
        transform: translate3d(0, 0, 0) scale(0);
        will-change: opacity, transform;
        opacity: .5;
        margin: 0 !important;
        border: none !important;
    }

    %igx-ripple-wrapper {
        overflow: hidden;
    }
}
