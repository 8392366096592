@use '../mixins' as *;

////
/// @package theming
/// @group animations
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @requires {mixin} keyframes
@mixin rotate-center {
    @include keyframes(rotate-center) {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-bl {
    @include keyframes(rotate-bl) {
        0% {
            transform: rotate(0);
            transform-origin: bottom left;
        }

        100% {
            transform: rotate(360deg);
            transform-origin: bottom left;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-vert-center {
    @include keyframes(rotate-vert-center) {
        0% {
            transform: rotateY(0);
        }

        100% {
            transform: rotateY(360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-diagonal-br {
    @include keyframes(rotate-diagonal-br) {
        0% {
            transform: rotate3d(-1, 1, 0, 0deg);
            transform-origin: 100% 100%;
        }

        100% {
            transform: rotate3d(-1, 1, 0, -360deg);
            transform-origin: 100% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-top {
    @include keyframes(rotate-top) {
        0% {
            transform: rotate(0);
            transform-origin: top;
        }

        100% {
            transform: rotate(360deg);
            transform-origin: top;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-left {
    @include keyframes(rotate-left) {
        0% {
            transform: rotate(0);
            transform-origin: left;
        }

        100% {
            transform: rotate(360deg);
            transform-origin: left;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-vert-left {
    @include keyframes(rotate-vert-left) {
        0% {
            transform: rotateY(0);
            transform-origin: left;
        }

        100% {
            transform: rotateY(360deg);
            transform-origin: left;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-diagonal-bl {
    @include keyframes(rotate-diagonal-bl) {
        0% {
            transform: rotate3d(1, 1, 0, 0deg);
            transform-origin: 0% 100%;
        }

        100% {
            transform: rotate3d(1, 1, 0, 360deg);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-tr {
    @include keyframes(rotate-tr) {
        0% {
            transform: rotate(0);
            transform-origin: top right;
        }

        100% {
            transform: rotate(360deg);
            transform-origin: top right;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-tl {
    @include keyframes(rotate-tl) {
        0% {
            transform: rotate(0);
            transform-origin: top left;
        }

        100% {
            transform: rotate(360deg);
            transform-origin: top left;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-up {
    @include keyframes(rotate-scale-up) {
        0% {
            transform: scale(1) rotateZ(0);
        }

        50% {
            transform: scale(2) rotateZ(180deg);
        }

        100% {
            transform: scale(1) rotateZ(360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-up-diag-1 {
    @include keyframes(rotate-scale-up-diag-1) {
        0% {
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
        }

        50% {
            transform: scale(2) rotate3d(1, 1, 0, -180deg);
        }

        100% {
            transform: scale(1) rotate3d(1, 1, 0, -360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-down {
    @include keyframes(rotate-scale-down) {
        0% {
            transform: scale(1) rotateZ(0);
        }

        50% {
            transform: scale(0.5) rotateZ(180deg);
        }

        100% {
            transform: scale(1) rotateZ(360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-down-diag-1 {
    @include keyframes(rotate-scale-down-diag-1) {
        0% {
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
        }

        50% {
            transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
        }

        100% {
            transform: scale(1) rotate3d(1, 1, 0, -360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-up-hor {
    @include keyframes(rotate-scale-up-hor) {
        0% {
            transform: scale(1) rotateX(0);
        }

        50% {
            transform: scale(2) rotateX(-180deg);
        }

        100% {
            transform: scale(1) rotateX(-360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-up-diag-2 {
    @include keyframes(rotate-scale-up-diag-2) {
        0% {
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
        }

        50% {
            transform: scale(2) rotate3d(-1, 1, 0, 180deg);
        }

        100% {
            transform: scale(1) rotate3d(-1, 1, 0, 360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-down-hor {
    @include keyframes(rotate-scale-down-hor) {
        0% {
            transform: scale(1) rotateX(0);
        }

        50% {
            transform: scale(0.5) rotateX(-180deg);
        }

        100% {
            transform: scale(1) rotateX(-360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-down-diag-2 {
    @include keyframes(rotate-scale-down-diag-2) {
        0% {
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
        }

        50% {
            transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
        }

        100% {
            transform: scale(1) rotate3d(-1, 1, 0, 360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-up-ver {
    @include keyframes(rotate-scale-up-ver) {
        0% {
            transform: scale(1) rotateY(0);
        }

        50% {
            transform: scale(2) rotateY(180deg);
        }

        100% {
            transform: scale(1) rotateY(360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-scale-down-ver {
    @include keyframes(rotate-scale-down-ver) {
        0% {
            transform: scale(1) rotateY(0);
        }

        50% {
            transform: scale(0.5) rotateY(180deg);
        }

        100% {
            transform: scale(1) rotateY(360deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-cw {
    @include keyframes(rotate-90-cw) {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(90deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-right-cw {
    @include keyframes(rotate-90-right-cw) {
        0% {
            transform: rotate(0);
            transform-origin: right;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: right;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-bl-cw {
    @include keyframes(rotate-90-bl-cw) {
        0% {
            transform: rotate(0);
            transform-origin: 0% 100%;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-horizontal-fwd {
    @include keyframes(rotate-90-horizontal-fwd) {
        0% {
            transform: rotateX(0);
        }

        100% {
            transform: rotateX(90deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-ccw {
    @include keyframes(rotate-90-ccw) {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(-90deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-right-ccw {
    @include keyframes(rotate-90-right-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: right;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: right;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-bl-ccw {
    @include keyframes(rotate-90-bl-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: 0% 100%;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: 0% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-horizontal-bck {
    @include keyframes(rotate-90-horizontal-bck) {
        0% {
            transform: rotateX(0);
        }

        100% {
            transform: rotateX(-90deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-top-cw {
    @include keyframes(rotate-90-top-cw) {
        0% {
            transform: rotate(0);
            transform-origin: top;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: top;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-br-cw {
    @include keyframes(rotate-90-br-cw) {
        0% {
            transform: rotate(0);
            transform-origin: 100% 100%;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: 100% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-left-cw {
    @include keyframes(rotate-90-left-cw) {
        0% {
            transform: rotate(0);
            transform-origin: left;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: left;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-vertical-fwd {
    @include keyframes(rotate-90-vertical-fwd) {
        0% {
            transform: rotateY(0);
        }

        100% {
            transform: rotateY(90deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-top-ccw {
    @include keyframes(rotate-90-top-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: top;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: top;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-br-ccw {
    @include keyframes(rotate-90-br-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: 100% 100%;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: 100% 100%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-left-ccw {
    @include keyframes(rotate-90-left-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: left;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: left;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-vertical-bck {
    @include keyframes(rotate-90-vertical-bck) {
        0% {
            transform: rotateY(0);
        }

        100% {
            transform: rotateY(-90deg);
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-tr-cw {
    @include keyframes(rotate-90-tr-cw) {
        0% {
            transform: rotate(0);
            transform-origin: top right;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: top right;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-bottom-cw {
    @include keyframes(rotate-90-bottom-cw) {
        0% {
            transform: rotate(0);
            transform-origin: bottom;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: bottom;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-tl-cw {
    @include keyframes(rotate-90-tl-cw) {
        0% {
            transform: rotate(0);
            transform-origin: 0% 0%;
        }

        100% {
            transform: rotate(90deg);
            transform-origin: 0% 0%;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-tr-ccw {
    @include keyframes(rotate-90-tr-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: top right;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: top right;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-bottom-ccw {
    @include keyframes(rotate-90-bottom-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: bottom;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: bottom;
        }
    }
}

/// @requires {mixin} keyframes
@mixin rotate-90-tl-ccw {
    @include keyframes(rotate-90-tl-ccw) {
        0% {
            transform: rotate(0);
            transform-origin: 0% 0%;
        }

        100% {
            transform: rotate(-90deg);
            transform-origin: 0% 0%;
        }
    }
}
