@use '../../base' as *;
@use 'sass:string';

/// @access private
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
@mixin component {
    @include b(igx-icon) {
        $this: bem--selector-to-string(&);
        @include register-component(
            $name: string.slice($this, 2, -1),
            $deps: ()
        );

        @extend %igx-icon-display !optional;

        @include m(inactive) {
            @extend %igx-icon--inactive !optional;
        }

        @include m(success) {
            @extend %igx-icon--success !optional;
        }

        @include m(error) {
            @extend %igx-icon--error !optional;
        }
    }

    @include b(igx-svg-container) {
        visibility: hidden;
        width: 0;
        height: 0;
        font-size: 0;
        overflow: hidden;
    }
}
