@use 'igniteui-theming/sass/bem' as *;

%drag {
    touch-action: none;
}

%drag--select-disabled {
    user-select: none;
}

%drag-handle {
    user-select: none;
}

@mixin component {
    @include b(igx-drag) {
        @extend %drag !optional;

        @include e(handle) {
            @extend %drag-handle !optional;
        }

        @include m(select-disabled) {
            @extend %drag--select-disabled !optional;
        }
    }
}
