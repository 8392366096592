@use 'sass:map';
@use '../../base' as *;
@use '../button-group/button-group-component' as *;
@use '../button-group/button-group-theme' as *;
@use '../tree/tree-theme' as *;
@use '../../themes/schemas' as *;

////
/// @group themes
/// @access private
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// @deprecated Use the `css-vars` mixin instead.
/// @see {mixin} css-vars
/// @param {Map} $theme - The grid theme used to style the component.
@mixin excel-filtering($theme) {
    $variant: map.get($theme, '_meta', 'variant');
    $theme-variant: map.get($theme, '_meta', 'theme-variant');
    $bootstrap-theme: $variant == 'bootstrap';

    $tree-node-indent: (
        comfortable: rem(16px),
        cosy: rem(8px),
        compact: rem(4px)
    );

    $tree-node-expander-size: rem(20px);

    $tree-node-height: (
        comfortable: rem(40px),
        cosy: rem(32px),
        compact: rem(24px)
    );

    $checkbox-indent: (
        comfortable: calc(#{map.get($tree-node-indent, 'comfortable')} + #{$tree-node-expander-size} + #{rem(8px)}),
        cosy: calc((#{map.get($tree-node-indent, 'cosy')} * 2) + #{$tree-node-expander-size}),
        compact: calc((#{map.get($tree-node-indent, 'compact')} * 2) + #{$tree-node-expander-size}),
    );

    %grid-excel-filter {
        --component-size: var(--ig-size, var(--ig-size-large));
        display: block;
        width: rem(320px);
        height: 100%;
        flex-grow: 1;

        @if $variant == 'indigo' {
            box-shadow: elevation(if($theme-variant == 'light', 3, 2)), 0 0 0 rem(1px) color(null, 'gray', if($theme-variant == 'light', 400, 100));

            // TODO: The border-radius should not be hardcoded.
            border-radius: border-radius(rem(4px));
        } @else {
            box-shadow: elevation(12);
        }

        overflow: auto;
        min-width: rem(320px);

    }

    %igx-excel-filter__sizing {
        @include sizable();

        min-height: sizable(
            rem(330px),
            rem(465px),
            rem(645px)
        );
        max-height: sizable(
            rem(405px),
            rem(565px),
            rem(775px)
        );
    }

    %grid-excel-filter--inline {
        box-shadow: none;
        width: 100%;
    }

    %igx-excel-filter__loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    %grid-excel-icon {
        display: flex;
        cursor: pointer;

        @if $variant == 'indigo' {
            opacity: if($theme-variant == 'light', .75, .85);

            &:hover {
                opacity: 1;
            }
        }

        @if $variant != 'indigo' {
            igx-icon {
                --size: var(--igx-icon-size, #{rem(15px)});
            }
        }
    }

    %grid-excel-icon--filtered {
        opacity: 1;

        igx-icon {
            color: if($variant == 'indigo', color($color: 'primary', $variant: 500), color($color: 'secondary'));
        }

        @if $variant == 'indigo' {
            &:hover {
                igx-icon {
                    color: color($color: 'primary', $variant: 400);
                }
            }
        }
    }

    %grid-excel-menu {
        --component-size: var(--ig-size, var(--ig-size-large));

        display: flex;
        flex-direction: column;
        height: 100%;

        // TODO: The border-radius should not be hardcoded.
        border-radius: border-radius(rem(4px));

        @if $variant != 'indigo' {
            background: var-get($theme, 'filtering-row-background');

            %igx-group-display {
                --shadow: none;
                --item-background: #{var-get($theme, 'filtering-row-background')};
                --item-hover-background: #{color($color: 'gray', $variant: 100)};
                --item-selected-background: #{color($color: 'gray', $variant: 100)};
                --item-text-color: #{color($color: 'gray', $variant: 700)};
                --item-icon-color: #{color($color: 'gray', $variant: 700)};
                --item-hover-text-color: #{color($color: 'gray', $variant: 800)};
                --item-hover-icon-color: #{color($color: 'gray', $variant: 800)};
                --item-selected-text-color: #{if(
                $variant == 'indigo',
                contrast-color($color: 'surface'),
                color($color: 'secondary', $variant: 500)
            )};
                --item-selected-icon-color: #{if(
                $variant == 'indigo',
                contrast-color($color: 'surface'),
                color($color: 'secondary', $variant: 500)
            )};
                --item-selected-hover-icon-color: #{if(
                $variant == 'fluent',
                color($color: 'secondary', $variant: 500),
                contrast-color($color: 'gray', $variant: 50)
            )};
                --item-border-color: transparent;
                --item-hover-border-color: transparent;
                --item-focused-border-color: #{if(
                $variant == 'fluent',
                color($color: 'gray', $variant: 700),
                transparent
            )};
                --item-selected-border-color: transparent;
                --item-selected-hover-border-color: transparent;
                --item-disabled-border: transparent;
                --disabled-selected-border-color: transparent;
            }
        } @else {
            @if $theme-variant == 'light' {
                background: contrast-color($color: 'gray', $variant: 900);
            } @else {
                background: color($color: 'gray', $variant: 50);
            }

            %igx-group-display {
                --item-background: transparent;
                --border-color: transparent;
                --item-border-color: transparent;
                --item-focused-border-color: transparent;
                --item-hover-border-color: transparent;
                --item-selected-border-color: transparent;
                --item-selected-hover-border-color: transparent;
                --item-disabled-border: transparent;
                --disabled-selected-border-color: transparent;
                --shadow: none;
            }

            %igx-group-item {
                &:not(:nth-child(1)) {
                    margin: 0;
                }
            }
        }

        @include tree(tree-theme(
            $background: color($color: 'surface'),
            $background-selected: color($color: 'surface'),
            $background-active: color($color: 'surface'),
            $background-active-selected: color($color: 'surface'),
            $foreground: contrast-color($color: 'surface'),
            $foreground-selected: contrast-color($color: 'surface'),
            $foreground-active: contrast-color($color: 'surface'),
            $foreground-active-selected: contrast-color($color: 'surface'),
        ));

        .igx-tree-node__wrapper {
            padding: 0;
        }

        igx-chips-area  {
            padding-inline: pad-inline(rem(4px), rem(8px), rem(16px));
            padding-block-start: pad-block(rem(4px), rem(8px), rem(16px));
            padding-block-end: 0;
            gap: sizable(rem(4px), rem(4px), rem(8px));
        }
    }

    %grid-excel-menu__header {
        display: flex;
        align-items: center;

        @if $variant == 'indigo' {
            padding: rem(16px) rem(16px) sizable(rem(8px), rem(12px), rem(16px));
        } @else {
            padding: pad(rem(4px), rem(8px), rem(16px));
        }

        color: var-get($theme, 'excel-filtering-header-foreground');
    }

    @if $variant == 'indigo' {
        .ig-typography %grid-excel-menu--compact {
            %grid-excel-menu__header {
                > h4 {
                    @include type-style('h6')
                }
            }
        }
    }

    %grid-excel-menu__header-actions {
        display: flex;
        margin-inline-start: auto;

        [igxButton] + [igxButton] {
            margin-inline-start: rem(4px);
        }

        %grid-excel-actions__action {
            padding: 0 !important;
            margin: 0 !important;
        }

        %grid-excel-actions__action,
        %grid-excel-actions--selected {
            justify-content: center;
        }
    }

    %grid-excel-menu__footer {
        display: flex;
        justify-content: space-between;

        %grid-excel-filter__apply,
        %grid-excel-filter__cancel {
            flex-grow: 1;

            [igxButton] {
                width: 100%;
            }
        }
    }

    %grid-excel-filter__clear {
        flex-grow: 1;
    }

    %grid-excel-filter__cancel + %grid-excel-filter__apply {
        margin-inline-start: rem(16px);
    }

    %grid-excel-sort,
    %grid-excel-move {
        display: block;

        @if $variant == 'indigo' {
            padding-inline: pad-inline(rem(16px));
        } @else {
            padding-block: pad-block(rem(4px), rem(8px), rem(8px));
            padding-inline: pad-inline(rem(4px), rem(8px), rem(16px));
        }

        header {
            color: var-get($theme, 'excel-filtering-subheader-foreground');

            @if $variant == 'indigo' {
                margin-block-end: sizable(rem(0), rem(4px), rem(4px)) !important;
                text-transform: capitalize !important;
            } @else {
                margin-block-end: rem(4px);
            }
        }

        igx-icon {
            --component-size: #{if($variant == 'indigo', 2, var(--grid-size))};

            display: initial;
            width: var(--size) !important;
            height: var(--size) !important;
            font-size: var(--size) !important;
        }
    }

    @if $variant == 'indigo' {
        %grid-excel-move {
            margin-block-end: sizable(rem(12px), rem(16px), rem(16px));
        }

        %grid-excel-sort + %grid-excel-move {
            margin-block-start: sizable(rem(4px), rem(8px), rem(8px));
        }

        %grid-excel-sort {
            padding-block-end: 0;
        }

        %grid-excel-actions__action {
            span {
                @include type-style('body-2');
            }
        }
    }

    %grid-excel-action--compact {
        display: flex;
        align-items: center;
        justify-content: space-between;

        header {
            margin-inline-end: auto;
        }
    }

    %grid-excel-actions {
        padding-block: pad-block(rem(4px), rem(8px), rem(8px));
        padding-inline: pad-inline(rem(4px), rem(16px), rem(16px));
    }

    %grid-excel-actions--selected {
        igx-icon {
            color: if(
                $variant == 'indigo',
                color($color: 'primary', $variant: 200),
                color($color: 'secondary')
            );
        }
    }

    %grid-excel-move .igx-button-group {
        [dir='rtl'] & {
            flex-direction: row-reverse;

            igx-icon,
            [igxButton] {
                direction: ltr;
            }
        }
    }

    %grid-excel-sort .igx-button-group {
        [dir='rtl'] & {
            flex-direction: row-reverse;

            igx-icon,
            [igxButton] {
                direction: ltr;
            }
        }
    }

    %grid-excel-actions__action {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @if $variant == 'indigo' {
            padding-block: pad-block(rem(6px));
            padding-inline: pad-inline(rem(12px));
            margin-inline: rem(8px);
            margin-block-end: rem(4px);
            border-radius: rem(4px);
        } @else {
            padding-block: pad-block(rem(4px), rem(8px), rem(8px));
            padding-inline: pad-inline(rem(4px), rem(8px), rem(16px));
        }

        cursor: pointer;
        color: var-get($theme, 'excel-filtering-actions-foreground');
        outline-style: none;

        &:hover,
        &:focus {
            color: var-get($theme, 'excel-filtering-actions-hover-foreground');

            @if $variant == 'indigo' {
                @if $theme-variant == 'light' {
                    background: color($color: 'gray', $variant: 200);
                } @else {
                    background: contrast-color($color: 'gray', $variant: 50, $opacity: .1);
                }

                igx-icon {
                    /* stylelint-disable max-nesting-depth */
                    @if $theme-variant == 'light' {
                        color: color($color: 'gray', $variant: 700);
                    } @else {
                        color: contrast-color($color: 'gray', $variant: 50, $opacity: .8);
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            } @else {
                background: color($color: 'gray', $variant: 100);
            }
        }

        @if $variant == 'indigo' {
            igx-icon {
                --component-size: 2;

                @if $theme-variant == 'light' {
                    color: color($color: 'gray', $variant: 600);
                } @else {
                    color: contrast-color($color: 'gray', $variant: 50, $opacity: .6);
                }
            }
        }

        [dir='rtl'] & {
            igx-icon {
                transform: scaleX(-1);
            }
        }
    }

    @if $variant == 'indigo' {
        %grid-excel-actions__action-filter {
            margin-block-end: 0;
        }
    }

    %grid-excel-actions__action--active {
        background: color($color: 'gray', $variant: 100);
        color: var-get($theme, 'excel-filtering-actions-hover-foreground');
    }

    %grid-excel-actions__action--disabled {
        color: var-get($theme, 'excel-filtering-actions-disabled-foreground');
        pointer-events: none;

        @if $variant == 'indigo' {
            igx-icon {
                color: var-get($theme, 'excel-filtering-actions-disabled-foreground');
            }
        }
    }

    %igx-excel-filter__empty {
        display: grid;
        place-items: center;
        height: 100%;
    }

    %grid-excel-main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;

        @if $variant == 'indigo' {
            padding: pad(rem(16px));
            gap: sizable(rem(16px));
        } @else {
            padding: pad(rem(4px), rem(8px), rem(16px));
            gap: sizable(rem(4px), rem(8px), rem(16px));
        }

        %igx-list {
            flex-grow: 1;
            overflow: hidden;

            @if $variant == 'indigo' {
                --background: #{color($color: 'surface', $variant: 500)};

                margin-inline: calc(sizable(rem(-16px)) * -1);

                // This is the only way to take the gap from the list,
                // otherwise we have to hardcoded here
                > div {
                    gap: inherit;
                }

                igx-display-container {
                    display: flex;
                    flex-direction: column;
                    gap: inherit;
                    padding: rem(8px);
                }
            } @else  {
                margin-inline: calc(sizable(rem(-4px), rem(-8px), rem(-16px)) * -1);
            }

            border: 0;

            @if $bootstrap-theme {
                border-top: rem(1px) dashed color($color: 'gray', $variant: 100);
                border-bottom: rem(1px) dashed color($color: 'gray', $variant: 100);
            } @else {
                border-top: rem(1px) dashed color($color: 'gray', $variant: 300);
                border-bottom: rem(1px) dashed color($color: 'gray', $variant: 300);
            }

            @if $variant == 'indigo' and $theme-variant == 'dark' {
                border-top: rem(1px) dashed color($color: 'gray', $variant: 100);
                border-bottom: rem(1px) dashed color($color: 'gray', $variant: 100);
            }
        }

        %igx-excel-filter__tree {
            background: color($color: 'surface');
            overflow-y: auto;
            margin-inline: calc(pad-inline(rem(-4px), rem(-8px), rem(-16px)) * -1);
            margin-block: 0;
            flex: 1;

            @if $bootstrap-theme {
                border-top: rem(1px) dashed color($color: 'gray', $variant: 100);
                border-bottom: rem(1px) dashed color($color: 'gray', $variant: 100);
            } @else {
                border-top: rem(1px) dashed color($color: 'gray', $variant: 300);
                border-bottom: rem(1px) dashed color($color: 'gray', $variant: 300);
            }

            @if $variant == 'indigo' and $theme-variant == 'dark' {
                border-top: rem(1px) dashed color($color: 'gray', $variant: 100);
                border-bottom: rem(1px) dashed color($color: 'gray', $variant: 100);
            }

            igx-icon {
                width: var(--igx-icon-size, #{$tree-node-expander-size});
                height: var(--igx-icon-size, #{$tree-node-expander-size});
                font-size: var(--igx-icon-size, #{$tree-node-expander-size});
            }

            > igx-checkbox,
            .igx-tree-node__wrapper {
                height: #{sizable(
                    map.get($tree-node-height, 'compact'),
                    map.get($tree-node-height, 'cosy'),
                    map.get($tree-node-height, 'comfortable')
                )};
                min-height: #{sizable(
                    map.get($tree-node-height, 'compact'),
                    map.get($tree-node-height, 'cosy'),
                    map.get($tree-node-height, 'comfortable')
                )};
            }

            .igx-tree-node__toggle-button {
                min-width: rem(20px);
                margin-inline-start: pad-inline(
                    map.get($tree-node-indent, 'compact'),
                    map.get($tree-node-indent, 'cosy'),
                    map.get($tree-node-indent, 'comfortable')
                );
                margin-inline-end: pad-inline(rem(4px), rem(8px));
            }

            .igx-tree {
                overflow-y: hidden;
            }
        }

        %igx-excel-filter__tree-alike {
            background: color($color: 'surface');
            display: flex;
            flex-direction: column;
            z-index: 1;
        }

        %igx-excel-filter__tree-alike-item {
            display: flex;
            align-items: center;
            height: sizable(
                map.get($tree-node-height, 'compact'),
                map.get($tree-node-height, 'cosy'),
                map.get($tree-node-height, 'comfortable')
            );
            background: color($color: 'surface');

            &:hover,
            &:focus {
                background: color($color: 'gray', $variant: 200);
            }

            > igx-checkbox {
                margin-inline-start: pad-inline(
                    map.get($checkbox-indent, 'compact'),
                    map.get($checkbox-indent, 'cosy'),
                    map.get($checkbox-indent, 'comfortable')
                );
            }
        }
    }

    %grid-excel-menu--cosy {

        %grid-excel-menu__header {
            justify-content: space-between;
        }
    }

    %grid-excel-menu--compact {

        %grid-excel-menu__header {
            justify-content: space-between;
        }

        %grid-excel-sort,
        %grid-excel-move {
            @extend %grid-excel-action--compact;

            igx-buttongroup {
                width: rem(208px);
            }
        }

        @if $variant != 'indigo' {
            %grid-excel-move {
                margin-bottom: 0;
            }
        }
    }

    %grid-excel-menu__secondary {
        width: rem(520px);
        min-width: rem(520px);
        background: var-get($theme, 'filtering-row-background');
        box-shadow: elevation(12);
        border-radius: border-radius(rem(4px));
    }

    %grid-excel-menu__condition {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @if $variant == 'indigo' {
            padding-inline: pad-inline(rem(16px));
        } @else {
            padding-inline: pad-inline(rem(4px), rem(8px), rem(16px));
        }

        padding-block: 0;

        igx-select {
            flex-grow: 1;
            flex-basis: 40%;
            margin: rem(16px) 0;

            ~ igx-input-group,
            ~ igx-date-picker,
            ~ igx-time-picker {
                margin-inline-start: rem(16px);
            }
        }

        igx-buttongroup {
            min-width: 30%;
        }

        [igxIconButton] {
            --component-size: var(--grid-size);
            margin-inline-start: rem(16px);
        }
    }

    %grid-excel-menu__add-filter {
        margin-inline: pad-inline(rem(4px), rem(4px), rem(16px));
        margin-block-start: 0;

        @if $bootstrap-theme {
            // important is needed to override the typography margins
            margin-block-end: rem(4px) !important;
        }

        igx-icon {
            width: var(--igx-icon-size, #{rem(18px)});
            height: var(--igx-icon-size, #{rem(18px)});
            font-size: var(--igx-icon-size, #{rem(18px)});
        }
    }

    %grid-excel-menu__secondary-header {
        @if $bootstrap-theme {
            border-bottom: rem(1px) solid color($color: 'gray', $variant: 100);
        } @else {
            border-bottom: rem(1px) solid color($color: 'gray', $variant: 300);
        }
    }

    %grid-excel-menu__secondary-main {
        height: rem(232px);
        overflow: auto;
    }

    %grid-excel-menu__secondary-footer {
        padding: rem(8px) rem(16px);

        @if $bootstrap-theme {
            border-top: rem(1px) dashed color($color: 'gray', $variant: 100);
        } @else {
            border-top: rem(1px) dashed color($color: 'gray', $variant: 300);
        }

        @if $variant == 'indigo' and $theme-variant == 'dark' {
            border-top: rem(1px) dashed color($color: 'gray', $variant: 100);
        }

        @if $variant == 'indigo' {
            padding: rem(16px);
        }

        %grid-excel-filter__apply,
        %grid-excel-filter__cancel {
            flex-grow: 0;
        }
    }

    %grid-excel-menu__filter-results {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    igx-excel-style-filter-operations,
    [igxExcelStyleFilterOperations] {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
    }
}

/// Adds typography styles for the excel-style-filtering component.
/// Uses the 'body-1', 'caption'
/// category from the typographic scale.
/// @group typography
/// @access private
/// @param {Map} $categories [(haeder-comfortable: 'overline', header-compact: 'subtitle-1')] - The categories from the typographic scale used for type styles.
@mixin excel-filtering-typography($categories: (
    header-comfortable: 'overline',
    header-compact: 'subtitle-1')
) {
    $header-comfortable: map.get($categories, 'header-comfortable');
    $header-compact: map.get($categories, 'header-compact');


    %grid-excel-menu {
        %grid-excel-menu__header > h4 {
            @include type-style('h6')
        }
    }

    %grid-excel-menu__secondary {
        %grid-excel-menu__header > h4 {
            @include type-style('h6');
        }
    }

    %grid-excel-sort,
    %grid-excel-move {
        header {
            @include type-style('overline');
        }
    }

    %grid-excel-menu--cosy {
        %grid-excel-menu__header > h4 {
            @include type-style('h6')
        }
    }

    %grid-excel-menu--compact {
        %grid-excel-menu__header > h4 {
            @include type-style('subtitle-1')
        }

        %grid-excel-sort,
        %grid-excel-move {
            header {
                @include type-style('body-2');
                text-transform: capitalize;
            }
        }

        %grid-excel-actions__action {
            span {
                @include type-style('body-2');
            }
        }

        %cbx-label {
            @include type-style('body-2');
        }
    }

    %grid-excel-menu__secondary--cosy {
        %grid-excel-menu__header > h4 {
            @include type-style('h6');
        }
    }

    %grid-excel-menu__secondary--compact {
        %grid-excel-menu__header > h4 {
            @include type-style('subtitle-1');
        }
    }
}
