////
/// @package theming
/// @group animations
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @type Timing Function
$in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);

/// @type Timing Function
$in-cubic: cubic-bezier(0.55, 0.55, 0.675, 0.19);

/// @type Timing Function
$in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);

/// @type Timing Function
$in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);

/// @type Timing Function
$in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);

/// @type Timing Function
$in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);

/// @type Timing Function
$in-circ: cubic-bezier(0.95, 0.05, 0.795, 0.035);

/// @type Timing Function
$in-back: cubic-bezier(0.95, 0.05, 0.795, 0.035);

/// @type Timing Function
$out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

/// @type Timing Function
$out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);

/// @type Timing Function
$out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

/// @type Timing Function
$out-quint: cubic-bezier(0.23, 1, 0.32, 1);

/// @type Timing Function
$out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);

/// @type Timing Function
$out-expo: cubic-bezier(0.19, 1, 0.22, 1);

/// @type Timing Function
$out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

/// @type Timing Function
$out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);

/// @type Timing Function
$in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

/// @type Timing Function
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

/// @type Timing Function
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

/// @type Timing Function
$in-out-quint: 0.5s cubic-bezier(0.86, 0, 0.07, 1);

/// @type Timing Function
$in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

/// @type Timing Function
$in-out-expo: cubic-bezier(1, 0, 0, 1);

/// @type Timing Function
$in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

/// @type Timing Function
$in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
