@use '../mixins' as *;

////
/// @package theming
/// @group animations
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
////

/// @requires {mixin} keyframes
@mixin fade-in-bck {
    @include keyframes(fade-in-bck) {
        0% {
            transform: translateZ(80px);
            opacity: 0;
        }

        100% {
            transform: translateZ(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-bl {
    @include keyframes(fade-in-bl) {
        0% {
            transform: translateX(-50px) translateY(50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-bottom {
    @include keyframes(fade-in-bottom) {
        0% {
            transform: translateY(50px);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-br {
    @include keyframes(fade-in-br) {
        0% {
            transform: translateX(50px) translateY(50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-fwd {
    @include keyframes(fade-in-fwd) {
        0% {
            transform: translateZ(-80px);
            opacity: 0;
        }

        100% {
            transform: translateZ(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-left {
    @include keyframes(fade-in-left) {
        0% {
            transform: translateX(-50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-right {
    @include keyframes(fade-in-right) {
        0% {
            transform: translateX(50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-tl {
    @include keyframes(fade-in-tl) {
        0% {
            transform: translateX(-50px) translateY(-50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-top {
    @include keyframes(fade-in-top) {
        0% {
            transform: translateY(-50px);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in-tr {
    @include keyframes(fade-in-tr) {
        0% {
            transform: translateX(50px) translateY(-50px);
            opacity: 0;
        }

        100% {
            transform: translateX(0) translateY(0);
            opacity: 1;
        }
    }
}

/// @requires {mixin} keyframes
@mixin fade-in {
    @include keyframes(fade-in) {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
